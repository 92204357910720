<template>
  <div class="page1">
    <div class="leftBox">
      <div class="chatBox">
        <div ref="chatRecordBox" class="chatRecordBox">
          <div v-for="(item, index) in chatRecordList" :key="index" class="message-container">
            <div class="left-role" v-if="item.role === 'service'">
              <div>{{ item.content }}</div>
            </div>
            <div class="right-role" v-if="item.role === 'customer'">
              <!-- {{ item.content  }} -->
              <span v-html="item.content"></span>
            </div>
            <!-- <view class="right-role"></view> -->
          </div>
        </div>
        <div class="toolBox">
          <ChooseEmotion @getEmo="chooseEmo($event, 'visitor')" />
        </div>
        <div
          ref="visitorSendContentBox_Ref"
          contenteditable="true"
          class="sendContentBox"
          @keydown="handleKeyDown"
        >
        </div>
        <div class="btnBox">
          <button @click="sendMsg" size="small" class="btn">发送</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OpenIMSDK,CbEvents } from 'open-im-sdk';
import axios from 'axios';
export default{
  data() {
    return {
      OpenIM: '',
      chatRecordList:[{
        role:'service',
        content: 'hi，我们是一家专业从事互联网软件定制开发的服务商，有着多年的开发经验及各行业的开发案例；请您先描述一下项目需求，会有专门客服人员跟您详细解答。也可以直接拨打 18955151876 与我们联系。' // 这是消息的内容
    }]
    }
  },
 async created(){
  this.OpenIM = new OpenIMSDK();

this.OpenIM.on(CbEvents.OnConnecting, ()=>{
  console.log('OnConnecting')
});
			this.OpenIM.on(CbEvents.OnConnectFailed,  () => {
				console.log('OnConnectFailed')
			});
			this.OpenIM.on(CbEvents.OnConnectSuccess, ()=>{
        console.log('OnConnectSuccess')
      });
			this.OpenIM.on(CbEvents.OnUserTokenExpired, () => {
				console.log('onUserTokenExpired')
			});
			this.OpenIM.on(CbEvents.OnKickedOffline, () => {
				console.log('OnKickedOffline')
			});
			this.OpenIM.on(CbEvents.OnRecvNewMessages, (e)=>{
        console.log('OnRecvNewMessages')
        console.log(e)
        if(e.data[0].contentType === 101){
          this.chatRecordList.push({
            role: "service",
            content: e.data[0].textElem.content,
            type: "text",
          })
          this.$emit('addUnread',{})
          this.scrollToBottom()
        }
      });
    await this.setCookie(); // 在组件挂载时调用 setCookie 方法
    // this.getToken(); // 在组件挂载时调用 getToken 方法
  },
  // mounted() {
  //   // 监听回车键事件
  //   this.$refs.visitorSendContentBox_Ref.addEventListener('keydown', this.sendMsg);
  // },
  methods:{
    setCookie() {
      axios({
        method: 'post', // 请求方法，可以根据实际情况选择 GET 或 POST
        url: 'https://www.yulianshuju.com/api/visitor.visitor/login', // 示例 API 地址
        withCredentials: true, // 跨域请求时是否携带凭证
        data: {},  // 请求体数据，可以根据需要修改
      })
      .then((response) => {
        console.log(response.data,'cookie');  // 打印响应数据
        // 进行 IM 登录操作
         this.OpenIM.login({
            userID: response.data.data.visitor.id.toString(),      // IM 用户 userID
            token: response.data.data.token.token,  // IM 用户令牌
            platformID: 5,   // 当前登录平台号，web端为5
            wsAddr: 'wss://openim.ruanjian.art/msg_web_gateway',
           apiAddr: 'https://openim.ruanjian.art/msg_gateway',
        })
          .then((loginRes) => {
            // 登录完成
            console.log(loginRes, 'loginRes');
          })
          .catch(({ errCode, errMsg }) => {
            // 登录失败
            console.log(errMsg, 'errMsg');
            this.$message({
              message: '当前网络环境不稳定，请稍后再试！',
              type: "error",
            })
          });
      })
      .catch((error) => {
        console.error('Request failed:', error);  // 捕获请求错误并打印
        this.text = 'request failed';  // 更新请求状态文本
      });
    },
    async sendMsg(){
      
      let content = this.$refs.visitorSendContentBox_Ref.innerHTML;
      if (!content) {
        this.$message({
          message: "请输入发送内容！",
          type: "warning",
        });
        return;
      }
      const createTextMessage = await this.OpenIM.createTextMessage(content)
      console.log('createTextMessage',createTextMessage)
      this.OpenIM.sendMessage({
      recvID: "admin1",
      groupID: "",
      message: createTextMessage['data']
      })
      this.chatRecordList.push({
        role: "customer",
        content: content,
        type: "text",
      })
      console.log(this.chatRecordList,'this.chatRecordList');
      this.$refs.visitorSendContentBox_Ref.innerHTML = "";
      this.scrollToBottom()
    },
    handleKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();  // 阻止默认行为（避免插入换行符）
        // 在这里可以执行你想要的操作，例如发送消息
        this.sendMsg();

        // 你可以在此清空内容或处理内容
      }
    },
    scrollToBottom(){
      const container = this.$refs.chatRecordBox;
      if (container) {
          setTimeout(()=>{
            container.scrollTop = container.scrollHeight;
            console.log(container.scrollTop ,container.scrollTop+20, 'container.scrollTop');
          },200)
        }
    }
  }
}
</script>

<style lang="less" scoped>
/* 基本的聊天框样式 */
.message-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  /* 用于保证每条消息之间有一点空间 */
}

.left-role, .right-role {
  max-width: 70%;  /* 设置最大宽度，避免消息超出 */
  padding: 10px;
  width: 200px;
  border-radius: 8px;
  margin: 5px;
  word-wrap: break-word;  /* 自动换行 */
  width: fit-content;
}

/* 左侧消息 - 服务端消息 */
.left-role {
  background-color: #f1f1f1;  /* 背景色 */
  color: #333;  /* 文本颜色 */
  align-self: flex-start;  /* 对齐到左边 */
  margin-left: 10px;  /* 给左边一点间距 */
}
.left-role img{
  width: 30px;
  height: 30px;
}
/* 右侧消息 - 客户端消息 */
.right-role {
  background-color: #cce7fe;  /* 背景色 */
  align-self: flex-end;  /* 对齐到右边 */
  margin-right: 10px;  /* 给右边一点间距 */
}

// .left-role{
//     width: fit-content;
//     border-radius: 3px;
//     padding: .625rem;
//     word-break: break-all;
//     background: #f4f5f7;
//     margin: 15px 0;
// }
// .right-role{
//     width: fit-content;
//     border-radius: 3px;
//     padding: .625rem;
//     word-break: break-all;
//     background: #cce7fe;
//     margin: 15px 0;
// }
.page {
  display: flex;
  justify-content: space-around;
}
.chatBox {
  width: 450px;
  /* padding: 10px; */
  /* background: #409eff; */
  border-radius: 18px;
}
.chatRecordBox {
  padding:  0;
  height: 245px;
  padding-top: 10px;
  /*border-radius: 10px; */
  background: white;
  overflow: auto;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(195, 187, 187);
}
.chatBox-title{
  display: flex;
  height: 60px;
  background: rgba(94, 117, 217, 1);
  border-radius: 18px 18px 0px 0px;
  align-items: center;
}
.avator{
  width: 45px;
  height: 45px;
  opacity: 1;
  border-radius: 26px;
  margin-left: 20px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 1);
}
.avator-size{
  width: 36px;
  height: 36px;
}
.title{
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 31.86px;
  color: rgba(255, 255, 255, 1);
  margin-left: 15px
}

.sendContentBox {
  height: 80px;
  padding: 0px 10px; 
  background: white;
  overflow: auto;
  outline: none;
  /* border-top: 1px solid rgb(195, 187, 187); */
}
.btn{
  width: 68px;
  height: 27px;
  opacity: 1;
  border-radius: 4px;
  background:  rgba(35, 109, 255, 1);
  border: none;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 26.06px;
  color: rgba(255, 255, 255, 1);
}
.btn.active {
  background: rgba(35, 109, 255, 1) 100%; /* 改变为新的背景颜色 */
}
.btnBox {
  padding-bottom: 15px;
  text-align: right;
}

h1 {
  line-height: 40px;
  font-weight: bold;
}
.toolBox {
  /* padding: 4px; */
  background: white;
  /* margin-top: 10px; */
  display: flex;
  align-items: center;
}

img {
  cursor: pointer !important;
  display: inline-block;
}
.imgIcon {
  margin-left: 8px;
  height: 20px;
  cursor: pointer;
}
.videoIcon {
  width: 22px;
  cursor: pointer;
  margin-left: 4px;
}
.audioIcon {
  width: 18px;
  cursor: pointer;
  margin-left: 4px;
  border-radius: 4px;
}
/* 右键快捷菜单的样式 */
.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}

.contextmenu li {
  margin: 0;
  padding: 7px 16px;
  cursor: pointer;
}

.contextmenu li:hover {
  background: #eee;
}

</style> 

