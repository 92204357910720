<template>
    <div class="content">
       <!-- Vanta 动效作为背景 -->
    <div class="background">
      <Birds v-if="Path === '/Index'"></Birds>
    </div>
      <div class="content-top" :class=" Path === '/Index' ? 'active' : Path === '/Case' ? 'background-image1' : Path === '/About' ? 'background-image2' : 'background-image1' " >
        <div class="nav-top">
          <img src="@/static/logos/company.png" class="icon-position">
          <div class="nav-text-active" >
            <template v-for="(item,index) in items" :key="index">
              <RouterLink :to="item.url">
                <div class="nav-text" @click="selectTitle(index)" :class="{navTextActive : currentIndex === index}">{{item.name}}</div>
              </RouterLink>
            </template>
          </div>
          <div class="text-login">
            <!-- <el-popover
              placement="bottom"
              :width="165"
              trigger="click"
            >
            <div>
              <span class="number">189 5515 1876</span>
              <span class="texta" @click="copyNumber">复制</span>
            </div>
              <template #reference>
                <div class="get-number">
                  <el-button class="m-2">
                    <img src="@/static/icon/number.png" class="icon-number">
                    获取手机号码
                    </el-button>
                </div>
              </template>
            </el-popover> -->
            <el-button @click="login" class="m-2" style="width: 130px;margin-left: 100px;">
                    <!-- <img src="@/static/icon/number.png" class="icon-number"> -->
                    登录
            </el-button>
          </div>
          <el-dialog v-model="dialogVisible" :before-close="handleClose" width="600">
            <div style="display: flex; justify-content: spbace-between">
                <img src="@/static/image/dialogBG.png" style="width: 240px;height: 240px;z-index:2;">
             <div class="loginInput">
                <el-form :model="form">
                  <el-form-item>
                    <div style="font-size: 20px;color: #fff;margin-left: 50%;transform: translateX(-50%);">登录</div>
                  </el-form-item>
                  <el-form-item :label-width="formLabelWidth">
                    <input class="input" type="text" v-model="form.name" placeholder="请输入账号">
                  </el-form-item>
                  <el-form-item :label-width="formLabelWidth">
                    <input class="input" type="text" v-model="form.password" placeholder="请输入密码">
                  </el-form-item>
                  <el-form-item>
                    <button class="button">登录</button>
                  </el-form-item>
                </el-form>
              </div>
          </div>
          </el-dialog>
          <!-- <div class="text-login">
            <el-popover
              placement="bottom"
              :width="150"
              trigger="click"
            >
            <div class="add">
              <img src="@/static/image/image.png">
              <div class="text"><span class="item">添加企业微信免费咨询</span></div>
            </div>
              <template #reference>
                <el-button class="m-2">
                  <img src="@/static/icon/weixin.png" class="icon-weixin">
                  添加企业微信
                  </el-button>
              </template>
            </el-popover>
          </div> -->
        </div>
        <div class="circle-sty">
          <!-- <bird3D v-if="Path === '/Index'"></bird3D> -->
          <!-- <BirdLock v-if="Path === '/Index'"></BirdLock> -->
          <!-- <Circle3D v-if="Path === '/Index'"></Circle3D> -->
           <!-- <Font3D v-if="Path === '/Index'"></Font3D> -->
          <!-- <SphereComponent v-if="Path === '/Index'"></SphereComponent> -->
          <!-- <div class="circle-breath"></div> -->
           <!-- <circleCircel></circleCircel> -->
        </div>

        <RouterView name="Top"></RouterView>
          <!-- <div class="phone-number">189 5515 1876</div> -->
          <GetPrice></GetPrice>
      </div>
      <RouterView name="Bottom"></RouterView>
    </div>
</template>
<script>
import GetPrice from '@/components/getPrice.vue';
import Birds from '@/components/birds.vue';
// import SphereComponent from '@/components/SphereComponent.vue';

import { OpenIMSDK,CbEvents } from 'open-im-sdk';
import axios from 'axios';
export default {
  created() {
    console.log(2132131321);
    
    this.OpenIM = new OpenIMSDK();

this.OpenIM.on(CbEvents.OnConnecting, ()=>{
  console.log('OnConnecting')
});
			this.OpenIM.on(CbEvents.OnConnectFailed,  () => {
				console.log('OnConnectFailed')
			});
			this.OpenIM.on(CbEvents.OnConnectSuccess, ()=>{
        console.log('OnConnectSuccess')
      });
			this.OpenIM.on(CbEvents.OnUserTokenExpired, () => {
				console.log('onUserTokenExpired')
			});
			this.OpenIM.on(CbEvents.OnKickedOffline, () => {
				console.log('OnKickedOffline')
			});
			this.OpenIM.on(CbEvents.OnRecvNewMessages, (e)=>{
        console.log('OnRecvNewMessages')
        console.log(e)
      });
    //  this.setCookie(); // 在组件挂载时调用 setCookie 方法


    this.updateBackgroundImage();
    this.$router.afterEach(() => {
      this.updateBackgroundImage();
    });
    console.log('this.$route.fullPath')

        // 从 localStorage 中读取 currentIndex 的值
        const savedIndex = localStorage.getItem('currentIndex');
        if (savedIndex !== null) {
          this.currentIndex = parseInt(savedIndex, 10);
        }
  },
  components:{
    // SphereComponent,
    GetPrice,
    Birds
  },
    data(){
      return{
        OpenIM: '',
        backgroundImage:'',
        dialogVisible:false,
        form:{
          name:'',
          password:'',

        },
        items:[
          {
            name: "首页",
            url:'/Index',
          },
          {
            name: "案例",
            url:'/Case',
          },
          {
            name: "关于我们",
            url:'/About',
          },
        ],
        currentIndex:0,
      }
    },
    computed : {
      Path() {
        return  this.$route.fullPath;
      }
    },
    methods:{
      setCookie() {
      axios({
        method: 'post', // 请求方法，可以根据实际情况选择 GET 或 POST
        url: 'https://www.yulianshuju.com/api/visitor.visitor/login', // 示例 API 地址
        withCredentials: true, // 跨域请求时是否携带凭证
        data: {},  // 请求体数据，可以根据需要修改
      })
      .then((response) => {
        console.log(response.data,'cookie');  // 打印响应数据
        // 进行 IM 登录操作
         this.OpenIM.login({
            userID: response.data.data.visitor.id.toString(),      // IM 用户 userID
            token: response.data.data.token.token,  // IM 用户令牌
            platformID: 5,   // 当前登录平台号，web端为5
           wsAddr: 'ws://47.96.138.240:10003',
           apiAddr: 'http://47.96.138.240:10002',
        })
          .then((loginRes) => {
            // 登录完成
            console.log(loginRes, 'loginRes');
          })
          .catch(({ errCode, errMsg }) => {
            // 登录失败
            console.log(errMsg,errCode, 'errMsg');
          });
      })
      .catch((error) => {
        console.error('Request failed:', error);  // 捕获请求错误并打印
        this.text = 'request failed';  // 更新请求状态文本
      });
    },
      login(){
        this.dialogVisible = true;
      },
      selectTitle(index){
      this.currentIndex = index
      console.log(this.currentIndex);

      // 将 currentIndex 的值保存到 localStorage
      localStorage.setItem('currentIndex', index);
      },
      updateBackgroundImage() {
        const path = this.$route.fullPath;
        if (path === '/Case') {
          this.backgroundImage = `url(${require('@/static/image/bg1.png')})`;
        } else if (path === '/About') {
          this.backgroundImage = `url(${require('@/static/image/bg2.png')})`;
        } 
      },
      async copyNumber() {
        const number = '123 1234 1234';
        try {
          await navigator.clipboard.writeText(number);
          this.$message.success('号码已复制到剪贴板');
        } catch (err) {
          this.$message.error('复制失败');
        }
      }
    },
    watch: {
    '$route.fullPath': 'updateBackgroundImage'
    }
}
</script>
<style lang="less" scoped>
input{
  width: 306px;
height: 18px;
background: #EEEEEE;
border-radius: 50px;
  border: none; /* 去除边框 */
  color: #333; /* 自定义字体颜色 */
  padding: 10px; /* 自定义内边距 */
  border-radius: 15px; /* 自定义圆角 */
  outline: none; /* 去除轮廓 */
  width: 240px;
}
.button{
width: 260px;
height: 35px;
border: none;
color: #fff;
background: linear-gradient(80deg, #6FCAB3 0%, #5E78D7 100%);
border-radius: 25px;
}
.loginInput{
  width: 300px;
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  padding:10px 20px;
  border-radius: 10px;
  z-index: 2;
}
.phone-number{
  position: fixed;
  right: 15px;
  top: 50%;
  font-size: 24px;
  background: linear-gradient(90deg, #ff7e5f, #feb47b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
 .circle-sty{ 
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50%;
  height: 100%;
}
.circle-breath {
            background: rgb(134, 245, 217);
            box-shadow: 0 0 0 0 rgba(115, 224, 169, 1);
            height: 450px;
            width: 450px;
            border-radius: 50%;
            animation: donghua 2.4s infinite;
        }

        @keyframes donghua {
            0% {
                transform: scale(0.60);
                /* 注意rgba中的a的设置 */
                box-shadow: 0 0 0 0 rgb(124, 218, 171);
            }

            50% {
                transform: scale(1);
                box-shadow: 0 0 0 36px rgba(204, 73, 152, 0%);
            }

            100% {
                transform: scale(0.60);
                box-shadow: 0 0 0 0 rgba(204, 73, 152, 0%);
            }
        }



.content{
  /* width: 1200px; */
  margin: 0 auto;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1; /* Ensures background is behind other content */
}
.content-top{
  height: 100vh;
  background-position: center center;
    /* 背景图不平铺 */
    background-repeat: no-repeat;
    /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
    background-attachment: fixed;
    /* 让背景图基于容器大小伸缩 */
    background-size: cover;

  padding: 20px;
}
.active{
  background: radial-gradient(60% 100% at 80% 20%,rgb(160, 228, 193),transparent),
  radial-gradient(90% 90% at center bottom,rgb(176, 167, 234),transparent);
}
.background-image1 {
    background-image: url("@/static/image/bg3.jpg");
    background-attachment: scroll;
  }
  .background-image2 {
    background-image: url("@/static/image/bg4.jpg");
    background-attachment: scroll;
  }
.nav-top{
  display: flex;
  align-items: center;
}
.icon-position{
  width: 20%;
  /* height: 37px; */
  opacity: 1;
  border: 0.3px solid undefined;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
}
.icon-position::before {
  content: "";
  display: block;
  padding-bottom: 100%; /* 保持高度与宽度相等 */
}
.nav-text-active{
  margin-left: 100px;
  width: 45%;
  z-index: 1;
}
a {
  text-decoration: none;
}
.nav-text{
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 19.21px;
  color: rgba(31, 29, 43, 1);
  text-align: left;
  vertical-align: top;
  margin-right: 69px;
  
}
.navTextActive{
  border-bottom: 2px solid;
  border-image-source: linear-gradient(to right, rgba(113, 216, 172, 1), rgba(97, 136, 208, 1));
  border-image-slice: 1;
}
.nav-text-active{
  display: flex;
  align-items: center;
}
.nav-text{
  height: 20px;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 19.21px;
  text-align: center;
  vertical-align: top;
}
.text-login{
  z-index: 1;
  margin: 0 10px;

   :deep(.el-button){
    border-radius: 25px;
    background: linear-gradient(135deg, rgba(115, 224, 169, 1) 0%, rgba(91, 104, 223, 1) 100%);
    border: none;
    color: #fff;
   }

  }
  .number{
    margin-right: 10px;
  }
  .texta{
    color: rgba(92, 111, 220, 1);
    cursor: pointer;
  }
  .icon-number{
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  .add{

    img{
      width: 120px;
      height: 120px;
    }
    .text{
      display: flex;
      justify-content: center;
       .item{
        width: 90px;
        text-align: center;
       }
    }
  }
  .icon-weixin{
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
  .background-image {
    background-image: url("../static/image/bg1.png");
  }
</style>